code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  
    monospace;
    
}

*{
  scroll-behavior: smooth;
}
*::before, *::after {
  box-sizing: border-box;
}
/*
  2. Remove default margin
*/
* {
  margin: 0;
}
/*
  Typographic tweaks!
  3. Add accessible line-height
  4. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(4,4,91,1) 100%, rgba(0,0,0,1) 100%);  
  font-size: clamp(00.65rem, 1vw, 1.2rem);
 
}
/*
  5. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
/*
  6. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  7. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  8. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

/* Estilos para navegadores baseados no WebKit (como Chrome, Safari) */
::-webkit-scrollbar {
  width: 12px; /* Largura da barra de rolagem */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Cor da parte "arrastável" da barra de rolagem */
  border-radius: 6px; /* Borda arredondada da parte "arrastável" */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Cor da parte não arrastável da barra de rolagem */
}

/* Estilos para navegadores baseados na Mozilla (como Firefox) */
/* Estes estilos podem variar entre versões do Firefox, então ajuste conforme necessário */


/* Outras propriedades comuns */
/* Tornar a barra de rolagem mais fina e transparente quando não estiver sendo usada */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Adicionar uma transição suave à cor da barra de rolagem */
::-webkit-scrollbar-thumb {
  transition: background-color 0.3s;
}