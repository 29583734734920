.container{
    width: 100%;
    height:  fit-content;
    display: flex;
    justify-content: flex-start;
    margin-top: -10%;
    margin-bottom: 3%;
    padding: 3% 0%;

    overflow: hidden;

}


.description{
    /* background-color: rebeccapurple; */
    width: 100%;
    padding: 0% 2%;
}

.itens{
    width: 85%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
;
   
}

.animatedChar{
    animation: pulse 2s infinite;
   
}

.text{
    width: 100%;
    /* background-color: rebeccapurple; */
    height: 30vh;

}
.redes_logo, .github_logo{
    width: 3vw;
    
}

.github_logo{
    filter: invert();
}
.img_default{

    width: 6vw;

}

.redes{
    display: flex;
    height: 100%;

    flex-direction: column;
    justify-content: space-evenly;
        
    margin-top: 5%;
}

.redes a{
    font-size: clamp(0.6rem, 1.4vw, 2rem);
}

@keyframes pulse {
    from{
       opacity: 1;
    }

    50%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media (max-width: 700px){
 
    .itens{
        width: 100%;
    
        align-items: center;
    }

    .img_default{
        width: 9vw;
    }

    .redes_logo, .github_logo{
        width: 5vw;
    }
}


@media (max-width: 500px){
    .itens{
        flex-direction: column;
    }
}
