.container{
    padding: 2%;
    background-color: white;
}

.contato{
    /* background-color: red; */
    display: flex;
    flex-direction: column;
}

.contato p {
    align-self: center; 
    padding: 4%;
    
    font-size: clamp(1rem, 1.3vw, 2rem);
    text-align: center;
    margin-bottom: 30px;
}
.inputList{
    display: flex;
    flex-direction: column;
    align-items: center;
   
    gap: 20px;
}

.inputList input, .inputList textarea{
    font-size: clamp(0.75rem, 1.3vw, 2rem);

    width: 50%;
    height: 5vh;
    padding-left: 10px;
    transition: all 300ms ease-out;
    border-radius: 10px;
}


.inputList textarea{
    height: 20vh;
}


.inputList button{
    display: flex;
    width: 150px;
    height: 60px;
    justify-content: center;
    align-items: center;
    color: white;
    cursor: pointer;
    border: 1px solid  white;
    border-radius: 10px;
    font-size: clamp(1rem, 1.3vw, 1.5rem);
    font-weight: normal;
    background-color: rgba(4,4,91,1) ;
    transition: all 300ms ease-out;
    text-decoration: none;
    margin-bottom: 5%;
}

.inputList button:hover{
    transition: all 300ms ease-in;
    background-color: white;
    transform: scale(1.1);

    border: 1px solid rgba(4,4,91,1) ;
    color: rgba(4,4,91,1) ;
}


.inputList input:focus, .inputList textarea:focus{
    transition: all 100ms ease-in;
    transform: scale(1.005);
    border: 3px solid rgba(4,4,91,1) ;
    outline: none;

}


@media (max-width: 950px){
    .inputList{
        padding: 4%;
    }
    .inputList input, .inputList textarea{
        width: 100%;
        
    }
}