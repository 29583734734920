

.container{

    width: 100%;
    height: fit-content;

    align-items: center;
    display: flex;
    flex-direction: column;
    
    color: #ffffff;
    cursor: pointer;
}


.container h2{
    font-size: clamp(00.8rem, 1.4vw, 2rem);
    margin-bottom: 5%;
    text-align: center;
}

.container p{
    text-align: justify;
    color: white;
    font-size: clamp(0.6rem ,0.8vw, 1.2rem);
    font-weight: bold;
    text-shadow: 2px 3px 4px black;
}

.animatedChar{
    animation: pulse 2s infinite;
   
}


@keyframes pulse {
    from{
       opacity: 1;
    }

    50%{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@media (max-width: 1081px) {
    .container p{
    font-size: clamp(0.6vw, 1vw, 1.4vw);

    }
}



@media (max-width: 733px) {
    .container p{
        font-size: clamp(0.5rem, 1.4vw, 1.2rem)

    }
}


@media (max-width: 600px){
    .container {
        font-size: clamp(1rem, 2vw, 2.3rem);    
    }
    .container p{
        font-size: 2.5vw;

    }
}