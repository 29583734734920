.container{
    display: flex;
    width: 100%;
    height: 200px;
    padding: 0% 2%;
    align-items: center;
    justify-content: center;
    background-color: black;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(4,4,91,1) 100%, rgba(0,0,0,1) 100%);  
}


.logo{
    color: white;
    display: flex;
    height: fit-content;
    justify-self: center;
    align-items: center;
    gap: 10px;

}

.logo img{
    width: 5vw;
    height: 5vw;
    filter: invert();
}