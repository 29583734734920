.container{
    color:  white;
    /* background-color: red; */
    border: 10px solid transparent;
    border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(171,175,199,1) 0%, rgba(42,70,215,1) 0%, rgba(9,22,91,1) 20%);
    border-image-width: 2px; /* Define a largura da borda (deve coincidir com a largura da borda definida acima) */
    border-image-slice: 1; /* Define a divisão do gradiente (neste caso, 1 significa que o gradiente cobre toda a borda) */
    width: 55%;
    flex: 1;
    padding: 2%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
    
}

.subtitle {
    display: flex;
    font-size: clamp(1.3rem, 2vw, 3rem);
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}
.challengeName{
    font-size: clamp(1rem, 1.5vw, 2.5rem);
    margin-bottom: 3%;
    text-decoration: underline;
    text-transform: capitalize;
}


.challengeName:hover{
    cursor: pointer
}


.container p{
    font-size: clamp(1rem, 1.5vw, 2.5rem);
    width: 100%;

    display: flex;
   
}

.buttonLink{
    margin-top: 5%;
    font-size: clamp(1rem, 1.3vw, 1.5rem);
    align-self: center;
    color: white;
}


@media (max-width: 800px){
    .container{
        width: 80%;
    }


}
