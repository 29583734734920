.container{
    background-color: white;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    color: transparent;
    padding: 0%;
    overflow: hidden;

}
.containerInverted{
    background-color: white;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: flex-end;
    color: transparent;
    padding: 0%; 
    transform: rotate(180deg);
}
.subcontainer{
    overflow: hidden;
    width: 100%;
    height: 20px;
    margin-top: -1vh;

    position: absolute;
    background-color: #FFFFFF; /* Cor de fundo */
    clip-path: polygon(0% 0%, 80% 0%, 75% 100%, 0% 100%); /* Define a forma de corte */
    rotate: 180deg;
}

