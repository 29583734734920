.container{
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(4,4,91,1) 100%, rgba(0,0,0,1) 100%);
    width: 100%;
    height: 15vh;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
 
}
.burguerDiv{
    display: none;
}

.mainFrame{
    width: 100%;
    background-color: black;
}
.navItens{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    list-style-type: none;

}

.navItens a{
    color: #FFFFFF;
    font-size: clamp(0.75rem, 1.2vw, 2rem);
    align-self: center;
    cursor: pointer;
    animation: fadein 1000ms;
    text-decoration: none;
}

.animatedHover{
    display: inline-block;
    position: relative;
    padding-bottom: 0.25rem;
}

.animatedHover::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 3px;
    transition: 0.25s ease-out;
  
    background-color: #ffffff;
   
}

.animatedHover:hover::before{
    width: 100%;
}

@keyframes fadein {
    from{
        opacity: 0%;
    }
    to{
        opacity: 100%;
    }
}


@media (max-width: 500px) {
    .navItens{
        display: none;
    }

    .burguerDiv{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        padding: 5%;
        align-items: flex-start;
    }
    .burguer{

        width: 30px;
        filter: invert();
        transition: 300ms all ease-out;

    }

    .burguer:hover{
        transition: 300ms all ease-in;
        cursor: pointer;
        transform: scale(1.1);
    }
}