.box{
    width: 19vw;
    height: 400px;
    padding: 2%;
    justify-content: space-between;
    border: 10px solid transparent;
    border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(171,175,199,1) 0%, rgba(42,70,215,1) 0%, rgba(9,22,91,1) 20%);
    border-image-width: 2px; /* Define a largura da borda (deve coincidir com a largura da borda definida acima) */
    border-image-slice: 1; /* Define a divisão do gradiente (neste caso, 1 significa que o gradiente cobre toda a borda) */
    

}
.none{ 
    display: none;
}
.container{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2vw;;
    color: #ffffff
}
.container p {
    width: 85%;

    text-align: justify ;
    color: white;
    font-size: clamp(00.65rem, 1vw, 1.2rem);
    text-shadow: 2px 2px 4px #000000, -2px -2px 4px #797979;
}

.container button{
    display: flex;
    width:  5.32197977647685vw;
    height: 2.660989888238425vw ;
  
    justify-content: center;
    align-items: center;
    font-size: clamp(00.65rem, 0.8vw, 1.2rem);

    text-decoration: none;
    cursor: pointer;
    border: 1px solid #0091FD;
    border-radius: 5px;
    background-color:
     #0091FD;
    color: #0091FD;
    color: #FFFFFF;
    transition: all 0.3s ease-out;
}

.container button:hover{
    transition: all  0.3s ease-in;
    color: #0091FD;
    background-color: #FFFFFF;

}


@media (max-width: 1081px) {
    .box{
        width: 25vw;
        height: 25vw;
        font-size: 1.7vw;
    }
    .container button{
        width:  7vw;
        height: 3.5vw ;
        font-size: 1.2vw;

    }

}

@media (max-width: 733px){
    .box{
        width: 35vw;
        height: 35vw;
        font-size: 2vw;

    }

    .container button{
        width:  11vw;
        height: 5.5vw ;
        font-size: 1.7vw;

    }
}


@media (max-width: 600px){
    .box{
        width: 60vw;
        height: 60vw;
        margin-left: 15%;

    }

    .container{
        font-size: clamp(1rem, 2vw, 2.3rem);

    }
    .container p{
        font-size: clamp(0.9rem, 1.5vw, 1.9rem);
    }

    .container button{
        width:  14vw;
        height: 7vw ;
        font-size: 2vw;

    }
}

@media (max-width: 360px){
    .container{
        font-size: 2vw;
    }
    .container p{
        font-size: 3.3vw;
    }
    .container button{
        width:  14vw;
        height: 4vw ;
        font-size: 3vw;

    }
}