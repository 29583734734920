.container{
    width: 100%;
    height: fit-content;
    background-color: #ffffff;
    color: black;
    padding: 2%;
    overflow: hidden;

    
}

.itemList{
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-evenly;
   
    

   
}

.itemListReversed{

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    padding: 3%;
}
.itemList img, .itemListReversed img{
    width: 40vw;
    align-self: center;

}

.itemListReversed img{
    border: 1px solid #ccc;
    margin-left: -3%
}


@media (max-width: 800px){
    .itemList, .itemListReversed{
    
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 30px;
        margin-bottom: 10%;
        padding: 0%;
    }

    .itemList img, .itemListReversed img{
        width: 350px;
        /* height: 200px; */
    }
    
}