.container{
    /* background-color: white; */
    
    margin-bottom: 3%;

}

.container h1{
    font-size: clamp(2rem, 3vw, 4.5rem);
    animation: showin 700ms;
    color: #FFFFFF;
}

.elements{
    display: flex;
    color: #FFFFFF;
    align-items: center;
    gap: 20px;
}

.bar{
    background-color:#FFFFFF;
    width: 2px ;
    height: clamp(1.7rem, 3vw, 5rem);
    color: transparent; /* Torna o texto transparente */
}


@keyframes showin {
    from{
        opacity: -2;
        margin: -100%;
    }
    to{
        margin: 0%;
        opacity: 1;
    }
}


@media (max-width: 500px){
    .container{
        margin-left: 5%;
    }
}