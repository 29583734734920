.container{
    width: 100%;
    height: fit-content;
    padding: 2%;
    /* padding-top: 5px; */
    margin-top: 4%; 
}
.infos{
    width: 100%;
  
    display: flex;
    flex-direction: column;
    gap: 10%;
}


.introduction{
    display: flex;
    width: 90%;
    justify-content: space-between;
    margin-top: -3%;
    align-items: center;
}

.introduction img{
    margin-right: 5%;
    width: 18vw;
}
.introduction p, .container p{
    color: white;
  
    font-size: clamp(1rem, 1.2vw, 1.8rem);
    width: 50%;
    text-align: justify;
}

.containerApiInfo{
    display: flex;
    margin-top: 3%;
    flex-direction: column;
}

.infosApi{
    font-size: 1.7vw;
    color: white;
    width: fit-content;
    padding: 1%;
    border-left: 2px solid white;
    border-right: 2px solid white;

    
    border-radius: 10px;
  
}

.desafios{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 2%;
    height: fit-content;
    justify-content: space-between;
 
    border-radius: 10px;
    margin-top: 5%;
    margin-bottom: 4%;
  
}


.infoDesafios{
   
    width: 24vw;
    height: 24vw;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    border: 1px solid blue;
    height: 300px;
    align-items: center;
    border-radius: 20px 0px;
   
}



.infoDesafios span{
    text-align: center;
    font-size: 1.9vw;
    margin-top: -5%;
    color: #0076FE;
    text-shadow: 1px 0px 1px #ccc;
    

}
.infoDesafios h2{
    font-size: 1.6vw;
    color: white;
    margin-bottom: 5%;
}


.containerApiInfo p{
    margin-top: 3%;
    width: 70%;
}

.invertedContainer{
    display: flex;
    margin-right: 15%;

}

.desafiosConcluidos{
    display: flex;
    flex-direction: column;
}

.invertedContainer img{
    width: 18vw;
    
 }


 .buttonLink{
    color: white;
    margin-top: 1%;
    font-size: 1vw;
    margin-bottom: 2%;
 }
 

 @media (max-width: 900px) {
    .desafios{
       flex-wrap: wrap;
      
    }

 }

@media (max-width: 800px){
    /* fazer repsonsividade */
    .introduction{
        flex-direction: column;
       
        width: 100%;
        gap: 20px;
    }

    .introduction p {
        width: 80%;
        padding: 2% 5%;
        margin-top: 3%;
    }
    
    .introduction img{
        width: 23vw;
    }

    .textAlign{
        display: flex;
        width: 95%;
        justify-content: center;
        margin-top: 5%;
    }
    .infosApi{
        font-size: clamp(1.4rem, 1.6vw, 1.8rem);
        
       
        text-align: center;
    }

    .invertedContainer {
        flex-direction: column;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-right: 0%;

    }

    .invertedContainer p {
   
        width: 80%;
        padding: 2% 5%;
        margin-top: 3%;
        margin-left: 3%;
        justify-self: center;
    }

    .invertedContainer img{
        width: 23vw;

    }
    .buttonLink{
        font-size: clamp(1rem, 1.3vw, 1.5rem);
        justify-self: center;
        text-align: center;
    }
}


 @media (max-width: 650px){
    .desafios{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .infoDesafios{
        
        width: 70vw;
    }

    .infoDesafios h2{
        font-size: clamp(1rem, 1.6vw, 2rem);
        
    }
    .infoDesafios span{
        font-size: clamp(1rem, 1.5vw, 2rem);
    }
}
