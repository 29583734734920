
.mainBox{
    height: 400px;
    display: flex;
    align-items: center;
   

   
    margin-top: 5%;
}
.container{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
 
 
    align-items: center;
    
    transition: all ease-in 300ms;
}

.itens{
    display: flex;
    width: 20%;
    align-items: center;
    justify-content: space-around;
    animation: popin 600ms;
}
.container h1{
    color: #FFFFFF;
    padding: 5%;
    font-size: clamp(2rem, 3vw, 3.5vw);
    margin-left: -2%;
    
}


.logo_img {
    filter: invert(100%);
    width: clamp(60px, 7.982969664715274vw,300px);
    height: clamp(60px, 7.982969664715274vw,300px);
    

}

.banner_img{
    width: 800px;
    position: fixed;
    top: 10%; /* A partir do topo da tela */
    left: 5%; /* A partir da margem direita da tela */
    opacity: 50%;
    z-index: -9999;
    
}

.bar{
    width: 2px;
    height: 100px;
    background-color: #FFFFFF;


}

.description{
    color: #767676;
    opacity: 50%;
    margin-top: 1%;
    font-style: italic;
    animation: popin 700ms;
}

@keyframes popin {
    from {
        margin-left: -100%;
    }
    to {
        margin-left: 0;
    }
}