.container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    width: 600px;
    color: #FFFF;
    text-shadow: 0px 3px black;
    flex: 1;
    height: 30vw;
    padding: 2%;
    border: 10px solid transparent;
    border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(171,175,199,1) 0%, rgba(42,70,215,1) 0%, rgba(9,22,91,1) 20%);
    border-image-slice: 1; /* Define a divisão do gradiente (neste caso, 1 significa que o gradiente cobre toda a borda) */
    border-image-width: 2px; /* Define a largura da borda (deve coincidir com a largura da borda definida acima) */
    border-radius: 20px;
    margin: 5px;
    
    justify-content: space-between
}

.container span{
    font-size: clamp(00.75rem, 1.3vw, 1.5rem);

}

.container p{
    margin-top: 5%;
    font-size: clamp(00.65rem, 1.1vw, 1.5rem);
    text-align: justify;
}

.container img{
    align-self: center;
    margin-top: 2%;

    
}


.container a{
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: white;
    font-size:1vw
    /* background-color: red; */
}


@media (max-width: 700px){
    .container{
        flex: auto;
        width: 30vw;
       
        height: fit-content;
    }
    .container p{
        font-size: clamp(0.65rem,2.6vw,3rem);
    }

    .container span{
        font-size: clamp(1rem, 3vw, 5rem);
    }
    .container a {
        font-size: clamp(1rem, 1.3vw, 2rem);

    }
}


@media (max-width: 500px){
    .container{
        width: 80%;
        
    }
    .container p{
        font-size: 1rem;
    }
    .container span{
        font-size: 1.2rem;
    }
}
